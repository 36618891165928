export const parseCategories = (
  param: AppFilters.IUmbracoCategoriesList
): AppFilters.IFilterCategory[] => {
  if (!param.properties.filterableCategories) {
    return [];
  }

  return param.properties.filterableCategories.map(
    (item: AppFilters.IUmbracoFilterableCategory) => {
      return {
        id: item.id,
        title: item.title,
        relatedPage: item.relatedPage,
        relatedPageLink: item.relatedPageLink,
      };
    }
  );
};

export const parseFilterSections = (
  param: AppFilters.IUmbracoFilterSectionProperty[]
): AppFilters.IFilterSection[] => {
  return param.map((item: AppFilters.IUmbracoFilterSectionProperty) => {
    return {
      id: item.properties.header,
      header: item.properties.header,
      variant: item.properties.variant,
      operand: item.properties.operand.toLowerCase() as AppFilters.TFilterOperator,
      attributeForFaceting: item.properties.attributeForFaceting,
      isOpen: item.properties.isOpen === '1',
      filterItems: item.properties.filterItems.map((filterItem: AppFilters.IUmbracoFilterItem) => {
        return {
          id: filterItem.id,
          title: filterItem.title,
        };
      }),
    };
  });
};

export const parseCustomGlobalStyles = (
  param: AppFilters.IUmbracoCustomGlobalStyles
): AppFilters.ICustomGlobalStyles => {
  return {
    defaultControlsBgColor:
      param.properties.defaultControlsBgColor[0]?.properties.colorPicker?.label || null,
    defaultControlsTextColor:
      param.properties.defaultControlsTextColor[0]?.properties.colorPicker?.label || null,
  };
};

export const parseCTAButtonData = (
  param: AppFilters.IUmbracoCTAButtonData
): AppFilters.ICTABtnData => {
  return {
    defaultBgColor: param.properties.defaultBgColor?.[0]?.properties.colorPicker?.label || null,
    defaultTextColor: param.properties.defaultTextColor?.[0]?.properties.colorPicker?.label || null,
    title: param.properties?.title,
  };
};

export const parseCheckboxStyles = (
  param: AppFilters.IUmbracoCheckboxStyles
): AppFilters.ICheckboxStyles => {
  return {
    bgColor: param?.properties.bgColor[0].properties.colorPicker?.label || null,
    signColor: param?.properties.signColor[0].properties.colorPicker?.label || null,
  };
};

export const parseFilterButtonsTexts = (
  param: AppFilters.IUmbracoFilterButtonsTexts
): AppFilters.IFilterButtonsTexts => ({
  filtersSelectLabel: param.properties.filtersSelectLabel,
  filtersHaveSelectedLabel: param.properties.filtersHaveSelectedLabel,
  filtersTogglerButtonText: param.properties.filtersTogglerButtonText,
  closeButtonText: param.properties.closeButtonText,
  seeMoreButtonText: param.properties.seeMoreButtonText,
  seeLessButtonText: param.properties.seeLessButtonText,
});
