const FILTER_NAVIGATION_PARAMS = {
  usualFacet: 'tags.id',
  preferredFacet: 'preferred.id',
  linkFacet: 'link',
  langFacet: 'lang',
  symptomsFacet: 'Symptoms.id',
  formatFacet: 'Format.id',
  childrenOnlyFacet: 'ChildrenOnly.id',
  productCategoryFacet: 'ProductCategory.id',
  articleCategoryFacet: 'ArticleCategory.id',
  articleTopicFacet: 'ArticleTopic.id',
  kidsHubArticleCategoryFacet: 'AdvancedChildrenHubArticleCategory.id',
  kidsHubArticleTopicFacet: 'AdvancedChildrenHubArticleTopic.id',
  quizAgeFacet: 'Age.id',
  quizSymptomsFacet: 'Symptoms.id',
  quizEffectFacet: 'Effect.id',
};

const indices = {
  searchSample: `${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}-search`,
};

module.exports = { FILTER_NAVIGATION_PARAMS, indices };
