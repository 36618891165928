import { FILTER_NAVIGATION_PARAMS } from 'utils/algolia/constants';

/**
 * The output logic is described here:
 * https://www.algolia.com/doc/api-reference/api-parameters/filters/
 *
 * Additional filter layer - it is used for filtering only by selected items
 */
const getAdditionalFilterParams = (links: string[]): string => {
  let response = ' AND (';
  links.forEach((link: string, index: number) => {
    response += `${index === 0 ? '' : ` OR `}${FILTER_NAVIGATION_PARAMS.linkFacet}:${link}`;
  });
  response += ')';

  return response;
};

const Helpers = {
  getAdditionalFilterParams,
};

export default Helpers;
